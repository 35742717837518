import type { AnalyticsEventPayload } from '@atlaskit/analytics-next';

import { type QueryIntent } from '../constants';
import { sha256Hash } from '../utils';

import type { commonAttributesType, NLPSearchResultSource } from './types';

const sanitizeSources = (sources: NLPSearchResultSource[]): { id: string; type: string }[] =>
	sources.map((source) => ({
		id: source.ari ?? source.id,
		type: source.type,
	}));

const has3PSources = (sources: NLPSearchResultSource[]): { id: string; type: string }[] =>
	sources.filter((source) => source.ari?.includes('third-party'));

// New instrumentation attributes must be added to this function to be included in all the events
/**
 * @deprecated use 'filterCommonAttributes' from common instead
 */
export const filterCommonAttributes = (props: commonAttributesType) => {
	const {
		appliedFilters,
		answerFormat,
		answerLength,
		answerCardType,
		followUps,
		sources,
		extraAttributes,
		searchSessionId,
		queryHash,
		baseQueryHash,
		query,
		answerString,
		apiSource,
		brand,
		sessionId,
	} = props;
	return {
		searchSessionId,
		queryHash,
		baseQueryHash,
		wordCount: (query.match(/\w+/g) || []).length,
		queryLength: query.length,
		answerHash: sha256Hash(answerString),
		appliedFilters,
		answerFormat,
		answerLength,
		answerCardType,
		followUpsCount: followUps.length,
		followUpsLength: followUps.map((followUp) => followUp.length),
		has3PSources: has3PSources(sources).length ? true : false,
		sources: sanitizeSources(sources),
		apiSource,
		brand,
		sessionId: sessionId,
		...extraAttributes,
	};
};

export const onAIAnswerDialogQueryIntent = (props: commonAttributesType): AnalyticsEventPayload => {
	const { query, source } = props;
	return {
		action: 'surfaced',
		actionSubject: 'aiAnswerDialog',
		actionSubjectId: 'queryIntent',
		source: source,
		eventType: 'track',
		attributes: {
			...filterCommonAttributes(props),
		},
		nonPrivacySafeAttributes: {
			query,
		},
	};
};

// When the ai answer dialog is closed while loading before answer is shown
export const onAIAnswerDialogDroppedOff = (props: commonAttributesType): AnalyticsEventPayload => {
	const { query, source } = props;
	return {
		action: 'dropoff',
		actionSubject: 'aiAnswerDialog',
		source: source,
		eventType: 'track',
		attributes: {
			...filterCommonAttributes(props),
		},
		nonPrivacySafeAttributes: {
			query,
		},
	};
};

// When the ai answer dialog is actually in the viewport
export const onAIAnswerDialogViewed = (props: commonAttributesType): AnalyticsEventPayload => {
	const { query, source } = props;
	return {
		action: 'viewed',
		actionSubject: 'aiAnswerDialog',
		source: source,
		eventType: 'track',
		attributes: {
			...filterCommonAttributes(props),
		},
		nonPrivacySafeAttributes: {
			query,
		},
	};
};

// When the ai answer loading dialog is initially shown to the user
export const onAIAnswerLoadingDialogShown = (
	props: commonAttributesType,
	isAnimated?: boolean,
): AnalyticsEventPayload => {
	const { query, source } = props;
	return {
		action: 'shown',
		actionSubject: 'aiAnswerLoadingDialog',
		source: source,
		eventType: 'track',
		attributes: {
			...filterCommonAttributes(props),
			isAnimated,
		},
		nonPrivacySafeAttributes: {
			query,
		},
	};
};

// When query intent detection api classifies intent, routes the ui position of a smart answer
export const onSmartAnswerRouteDetection = (
	props: commonAttributesType,
	smartAnswersRoute: QueryIntent,
): AnalyticsEventPayload => {
	const { query, source } = props;
	return {
		action: 'classified',
		actionSubject: 'aiAnswerRoute',
		source: source,
		eventType: 'track',
		attributes: {
			...filterCommonAttributes(props),
			smartAnswersRoute: smartAnswersRoute,
		},
		nonPrivacySafeAttributes: {
			query,
		},
	};
};

export const onAIAnswerDialogUnmount = ({
	isReadingAids,
	sourceProduct,
	dwellTime,
	loadTime,
	...props
}: commonAttributesType & {
	isReadingAids: boolean;
	searchSessionId: string;
	source: string;
	sourceProduct?: string;
	dwellTime?: number;
	loadTime?: number;
}): AnalyticsEventPayload => {
	const { query, source, searchSessionId } = props;
	return {
		action: 'unmounted',
		actionSubject: 'aiAnswerDialog',
		source: source,
		eventType: 'track',
		attributes: {
			...filterCommonAttributes(props),
			aiFeatureName: isReadingAids
				? `Definitions ${sourceProduct ? `(${sourceProduct})` : ''}`
				: 'smartAnswers',
			aiInteractionId: isReadingAids ? undefined : searchSessionId,
			aiInteractionType: isReadingAids ? undefined : 'searchSessionId',
			dwellTime,
			loadTime,
		},
		nonPrivacySafeAttributes: {
			query,
		},
	};
};

// When the ai answer result is successfully displayed to the user
export const onAIAnswerResultShown = ({
	isStreamed,
	loadTime,
	...props
}: commonAttributesType & {
	isStreamed: boolean;
	loadTime?: number;
}): AnalyticsEventPayload => {
	const { query, answerString, source } = props;
	return {
		action: 'shown',
		actionSubject: 'aiAnswerResult',
		source: source,
		eventType: 'track',
		attributes: {
			...filterCommonAttributes(props),
			isStreamed,
			loadTime,
		},
		nonPrivacySafeAttributes: {
			query,
			answerString,
		},
	};
};

// When there is no ai generated answer to the question
export const onAIAnswerNotShown = ({
	isStreamed,
	loadTime,
	...props
}: commonAttributesType & {
	isStreamed: boolean;
	loadTime?: number;
}): AnalyticsEventPayload => {
	const { query, answerString, source, errorState } = props;
	return {
		action: 'shown',
		actionSubject: 'aiAnswerError',
		source: source,
		eventType: 'track',
		attributes: {
			...filterCommonAttributes(props),
			isStreamed,
			loadTime,
			errorState,
		},
		nonPrivacySafeAttributes: {
			query,
			answerString,
		},
	};
};

// Tracking clicks on the expand button in SAIN answer cards
export const aiAnswerExpandClicked = ({
	...props
}: commonAttributesType): AnalyticsEventPayload => {
	const { query, answerString } = props;
	return {
		action: 'clicked',
		actionSubject: 'aiAnswerExpand',
		eventType: 'track',
		attributes: {
			...filterCommonAttributes(props),
		},
		nonPrivacySafeAttributes: {
			query,
			answerString,
		},
	};
};

// Tracking clicks on the collapse button in SAIN answer cards
export const aiAnswerCollapseClicked = ({
	...props
}: commonAttributesType): AnalyticsEventPayload => {
	const { query, answerString } = props;
	return {
		action: 'clicked',
		actionSubject: 'aiAnswerCollapse',
		eventType: 'track',
		attributes: {
			...filterCommonAttributes(props),
		},
		nonPrivacySafeAttributes: {
			query,
			answerString,
		},
	};
};

// Tracking when the SAIN answer card is displayed in the expanded state, ie the answer is too short to collapse.
export const aiAnswerExpandShown = ({ ...props }: commonAttributesType): AnalyticsEventPayload => {
	const { query, answerString } = props;
	return {
		action: 'shown',
		actionSubject: 'aiAnswerExpand',
		eventType: 'track',
		attributes: {
			...filterCommonAttributes(props),
		},
		nonPrivacySafeAttributes: {
			query,
			answerString,
		},
	};
};

// When the user clicks the source links from the ai generated answer
export const onAIAnswerSourceClick = ({
	sourceIndex,
	hostname,
	...props
}: commonAttributesType & { sourceIndex: number; hostname?: string }): AnalyticsEventPayload => {
	const { query, answerString, source } = props;
	return {
		action: 'clicked',
		actionSubject: 'aiAnswerSourceLink',
		source: source,
		eventType: 'ui',
		attributes: {
			...filterCommonAttributes(props),
			sourceIndex,
			...(hostname
				? {
						metadata: {
							hostname,
						},
					}
				: {}),
		},
		nonPrivacySafeAttributes: {
			query,
			answerString,
		},
	};
};

export const onAIAnswerSourceContextMenuClick = ({
	sourceIndex,
	...props
}: commonAttributesType & { sourceIndex: number }): AnalyticsEventPayload => {
	const { query, answerString, source } = props;
	return {
		action: 'contextMenuClicked',
		actionSubject: 'aiAnswerSourceLink',
		source: source,
		eventType: 'ui',
		attributes: {
			...filterCommonAttributes(props),
			sourceIndex,
		},
		nonPrivacySafeAttributes: {
			query,
			answerString,
		},
	};
};

// When the user clicks the expand toggle button for the source section of the ai answers result
export const onAIAnswerSourceExpandToggleClick = ({
	expanded,
	...props
}: commonAttributesType & { expanded: boolean }): AnalyticsEventPayload => {
	const { query, answerString, source } = props;
	return {
		action: 'clicked',
		actionSubject: 'aiAnswerSourceLink',
		source: source,
		eventType: 'ui',
		attributes: {
			...filterCommonAttributes(props),
			expanded,
		},
		nonPrivacySafeAttributes: {
			query,
			answerString,
		},
	};
};

// When the user clicks the return link to get back to the search results from the ai generated answer
export const onAIAnswerReturnLinkClick = (props: commonAttributesType): AnalyticsEventPayload => {
	const { query, answerString, source } = props;
	return {
		action: 'clicked',
		actionSubject: 'aiAnswerReturnLink',
		source: source,
		eventType: 'ui',
		attributes: {
			...filterCommonAttributes(props),
		},
		nonPrivacySafeAttributes: {
			query,
			answerString,
		},
	};
};

// When the user clicks the like button for the ai generated answer
export const onLikeButtonClick = (props: commonAttributesType): AnalyticsEventPayload => {
	const { query, answerString, source } = props;
	return {
		action: 'clicked',
		actionSubject: 'aiAnswerLikeButton',
		source: source,
		eventType: 'ui',
		attributes: {
			...filterCommonAttributes(props),
		},
		nonPrivacySafeAttributes: {
			query,
			answerString,
		},
	};
};

export const onDislikeButtonClick = (props: commonAttributesType): AnalyticsEventPayload => {
	const { query, answerString, source } = props;
	return {
		action: 'clicked',
		actionSubject: 'aiAnswerDislikeButton',
		source: source,
		eventType: 'ui',
		attributes: {
			...filterCommonAttributes(props),
		},
		nonPrivacySafeAttributes: {
			query,
			answerString,
		},
	};
};

export const onReportButtonClick = (props: commonAttributesType): AnalyticsEventPayload => {
	const { query, answerString, source } = props;
	return {
		action: 'clicked',
		actionSubject: 'aiAnswerReportButton',
		source: source,
		eventType: 'ui',
		attributes: {
			...filterCommonAttributes(props),
		},
		nonPrivacySafeAttributes: {
			query,
			answerString,
		},
	};
};

export const onFollowUpSuggestionClick = ({
	followUpString,
	...props
}: commonAttributesType & {
	followUpString: string;
}): AnalyticsEventPayload => {
	const { query, answerString, source } = props;
	return {
		action: 'clicked',
		actionSubject: 'aiFollowUpSuggestion',
		source: source,
		eventType: 'ui',
		attributes: {
			...filterCommonAttributes(props),
			followUpHash: sha256Hash(followUpString),
		},
		nonPrivacySafeAttributes: {
			query,
			answerString,
			followUpString,
		},
	};
};

export const onExpandButtonClicked = ({
	expandButtonSource,
	...props
}: commonAttributesType & {
	expandButtonSource: string;
}): AnalyticsEventPayload => {
	const { query } = props;
	return {
		action: 'clicked',
		actionSubject: 'expandButton',
		actionSubjectId: expandButtonSource,
		source: props.source,
		eventType: 'ui',
		attributes: {
			...filterCommonAttributes(props),
		},
		nonPrivacySafeAttributes: {
			query,
		},
	};
};

// Click on primary result user profile for who question-answer
export const onWhoPrimaryViewProfileClick = ({
	primaryUserName,
	...props
}: commonAttributesType & {
	primaryUserName: string;
}): AnalyticsEventPayload => {
	const { query, answerString, source } = props;
	return {
		action: 'clicked',
		actionSubject: 'aiAnswerWhoPrimaryViewProfile',
		source,
		eventType: 'ui',
		attributes: {
			...filterCommonAttributes(props),
		},
		nonPrivacySafeAttributes: {
			query,
			answerString,
			primaryUserName,
		},
	};
};

// Right click on primary result user profile for who question-answer
export const onWhoPrimaryViewProfileContextMenuClick = ({
	primaryUserName,
	...props
}: commonAttributesType & {
	primaryUserName: string;
}): AnalyticsEventPayload => {
	const { query, answerString, source } = props;
	return {
		action: 'contextMenuClicked',
		actionSubject: 'aiAnswerWhoPrimaryViewProfile',
		source,
		eventType: 'ui',
		attributes: {
			...filterCommonAttributes(props),
		},
		nonPrivacySafeAttributes: {
			query,
			answerString,
			primaryUserName,
		},
	};
};

// Click on primary result pill e.g. one of the frequent collaborators or locations for who question-answer
export const onWhoPrimaryCoordinationPillClick = ({
	resultSection,
	resultTitle,
	...props
}: commonAttributesType & {
	resultSection: string;
	resultTitle: string;
}): AnalyticsEventPayload => {
	const { query, answerString, source } = props;
	return {
		action: 'clicked',
		actionSubject: 'aiAnswerWhoPrimaryCoordinationPill',
		source,
		eventType: 'ui',
		attributes: {
			...filterCommonAttributes(props),
		},
		nonPrivacySafeAttributes: {
			query,
			answerString,
			resultTitle,
		},
	};
};

// Right click on primary result pill e.g. one of the frequent collaborators or locations for who question-answer
export const onWhoPrimaryCoordinationPillContextMenuClick = ({
	resultSection,
	resultTitle,
	...props
}: commonAttributesType & {
	resultSection: string;
	resultTitle: string;
}): AnalyticsEventPayload => {
	const { query, answerString, source } = props;
	return {
		action: 'contextMenuClicked',
		actionSubject: 'aiAnswerWhoPrimaryCoordinationPill',
		source,
		eventType: 'ui',
		attributes: {
			...filterCommonAttributes(props),
		},
		nonPrivacySafeAttributes: {
			query,
			answerString,
			resultTitle,
		},
	};
};

// Click on additional match user profile for who question-answer
export const onWhoAdditionalMatchProfileClick = ({
	additionalMatchUserName,
	additionalMatchTitle,
	...props
}: commonAttributesType & {
	additionalMatchUserName: string;
	additionalMatchTitle: string | null;
}): AnalyticsEventPayload => {
	const { query, answerString, source } = props;
	return {
		action: 'clicked',
		actionSubject: 'aiAnswerWhoAdditionalMatchViewProfile',
		source,
		eventType: 'ui',
		attributes: {
			...filterCommonAttributes(props),
		},
		nonPrivacySafeAttributes: {
			query,
			answerString,
			additionalMatchUserName,
			additionalMatchTitle,
		},
	};
};

// Right click on additional match user profile for who question-answer
export const onWhoAdditionalMatchProfileContextMenuClick = ({
	additionalMatchUserName,
	additionalMatchTitle,
	...props
}: commonAttributesType & {
	additionalMatchUserName: string;
	additionalMatchTitle: string | null;
}): AnalyticsEventPayload => {
	const { query, answerString, source } = props;
	return {
		action: 'contextMenuClicked',
		actionSubject: 'aiAnswerWhoAdditionalMatchViewProfile',
		source,
		eventType: 'ui',
		attributes: {
			...filterCommonAttributes(props),
		},
		nonPrivacySafeAttributes: {
			query,
			answerString,
			additionalMatchUserName,
			additionalMatchTitle,
		},
	};
};

export const onRecentWorkItemClick = ({
	index,
	url,
	...props
}: commonAttributesType & {
	index: number;
	url: string;
}): AnalyticsEventPayload => {
	const { query, answerString, source } = props;
	return {
		action: 'clicked',
		actionSubject: 'aiAnswerRecentWorkItem',
		source,
		eventType: 'ui',
		attributes: {
			...filterCommonAttributes(props),
		},
		nonPrivacySafeAttributes: {
			query,
			url,
			answerString,
		},
	};
};

export const onRecentWorkItemContextMenuClick = ({
	index,
	url,
	...props
}: commonAttributesType & {
	index: number;
	url: string;
}): AnalyticsEventPayload => {
	const { query, answerString, source } = props;
	return {
		action: 'contextMenuClicked',
		actionSubject: 'aiAnswerRecentWorkItem',
		source,
		eventType: 'ui',
		attributes: {
			...filterCommonAttributes(props),
		},
		nonPrivacySafeAttributes: {
			query,
			url,
			answerString,
		},
	};
};

export const onRecentWorkLinkClick = (props: commonAttributesType): AnalyticsEventPayload => {
	const { query, answerString, source } = props;
	return {
		action: 'clicked',
		actionSubject: 'aiAnswerRecentWorkListLink',
		source,
		eventType: 'ui',
		attributes: {
			...filterCommonAttributes(props),
		},
		nonPrivacySafeAttributes: {
			query,
			answerString,
		},
	};
};

export const onRecentWorkLinkContextMenuClick = (
	props: commonAttributesType,
): AnalyticsEventPayload => {
	const { query, answerString, source } = props;
	return {
		action: 'contextMenuClicked',
		actionSubject: 'aiAnswerRecentWorkListLink',
		source,
		eventType: 'ui',
		attributes: {
			...filterCommonAttributes(props),
		},
		nonPrivacySafeAttributes: {
			query,
			answerString,
		},
	};
};

// Curated Definitions
// Megan Evans from ds team requested subjects be prepended with 'ai' for data pipeline purposes

// Event when definition edit button is clicked
export const onCuratedDefinitionEditButtonClicked = (
	props: commonAttributesType,
): AnalyticsEventPayload => {
	const { query, answerString, extraAttributes, source } = props;
	return {
		action: 'clicked',
		actionSubject: 'aiCuratedDefinitionEditButton',
		source,
		eventType: 'ui',
		attributes: {
			// contentId and readingAidsSessionId passed in from Confluence readingAids
			...extraAttributes,
		},
		nonPrivacySafeAttributes: {
			query,
			answerString,
		},
	};
};

// Event when edit definition modal next button is clicked
export const onCuratedDefinitionNextButtonClicked = (
	props: commonAttributesType,
): AnalyticsEventPayload => {
	const { query, answerString, extraAttributes, source } = props;
	return {
		action: 'clicked',
		actionSubject: 'aiCuratedDefinitionNextButton',
		source,
		eventType: 'ui',
		attributes: {
			// contentId and readingAidsSessionId passed in from Confluence readingAids
			...extraAttributes,
		},
		nonPrivacySafeAttributes: {
			query,
			answerString,
		},
	};
};

// Event when edit definition modal update button is clicked
export const onCuratedDefinitionUpdateButtonClicked = ({
	commonAttributes,
	newDefinition,
	restrictedScope,
	sourceSelected,
	aiDefinitionEdited,
}: {
	commonAttributes: commonAttributesType;
	newDefinition: string;
	restrictedScope: string;
	sourceSelected: boolean;
	aiDefinitionEdited: boolean;
}): AnalyticsEventPayload => {
	const {
		query: keyPhrase,
		answerString: oldDefinition,
		extraAttributes,
		source,
	} = commonAttributes;
	return {
		action: 'clicked',
		actionSubject: 'aiCuratedDefinitionUpdateButton',
		source,
		eventType: 'ui',
		attributes: {
			// contentId and readingAidsSessionId passed in from Confluence readingAids
			...extraAttributes,
			restrictedScope,
			sourceSelected,
			newDefinitionLength: newDefinition.length,
			// whether the user is editing an AI answer or already edited definition
			aiDefinitionEdited,
		},
		nonPrivacySafeAttributes: {
			keyPhrase,
			oldDefinition,
			newDefinition,
		},
	};
};

export const onCuratedDefinitionSeeEditHistoryButtonClicked = (
	props: commonAttributesType,
): AnalyticsEventPayload => {
	const { query, answerString, extraAttributes, source } = props;
	return {
		action: 'clicked',
		actionSubject: 'aiCuratedDefinitionSeeEditHistoryButton',
		source,
		eventType: 'ui',
		attributes: {
			// contentId and readingAidsSessionId passed in from Confluence readingAids
			...extraAttributes,
		},
		nonPrivacySafeAttributes: {
			query,
			answerString,
		},
	};
};

export const onCuratedDefinitionEditButtonShown = (
	props: commonAttributesType,
): AnalyticsEventPayload => {
	const { query, answerString, extraAttributes, source } = props;
	return {
		action: 'shown',
		actionSubject: 'aiCuratedDefinitionEditButton',
		source,
		eventType: 'track',
		attributes: {
			...extraAttributes,
		},
		nonPrivacySafeAttributes: {
			query,
			answerString,
		},
	};
};
