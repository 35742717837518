import React, { useCallback, useState } from 'react';

import FeatureGates from '@atlaskit/feature-gate-js-client';
import ChevronDownIcon from '@atlaskit/icon/utility/migration/chevron-down';
import ChevronRightIcon from '@atlaskit/icon/utility/migration/chevron-right';
import { Box, Inline, Stack, xcss } from '@atlaskit/primitives';
import { usePublish } from '@atlassian/conversation-assistant-pubsub';
import { FollowUpButton } from '@atlassian/conversation-assistant-ui-components';

import { AiBrand } from '../../constants';
import { useAIAnswerContext } from '../ai-answer-context';
import { onFollowUpSuggestionClick } from '../analytics';

import { AIAnswerFollowupBody, AIAnswerFollowUpPill, AIAnswerFollowUpWrapper } from './styled';

const iconWrapperStyles = xcss({
	width: '24px',
	height: '24px',
	display: 'flex',
});

export const FollowUpSuggestionFooter = () => {
	const {
		commonAttributes,
		fireAnalyticsEvent,
		setQuery,
		updateContextCache,
		onClose,
		closeParentDialog,
		brand,
		callbacks,
	} = useAIAnswerContext();
	const { followUps, query, answerString, searchSessionId } = commonAttributes;
	const publish = usePublish('ai-mate');

	const [showAllFollowUps, setShowAllFollowups] = useState(false);
	const canExpand = followUps.length > 1;
	const visibleFollowUps = showAllFollowUps ? followUps : followUps.slice(0, 1);

	const onPillClick = useCallback(
		(followUp: string) => {
			if (FeatureGates.checkGate('search_ai_followup_use_ai_mate') && brand === AiBrand.ROVO) {
				publish({
					type: 'chat-new',
					source: 'sain',
					data: {
						name: followUp.substring(0, 30),
						dialogues: [
							{
								human_message: { content: query },
								agent_message: { content: answerString },
							},
						],
						prompt: followUp,
						sourceId: searchSessionId,
					},
				});
			} else {
				setQuery(followUp);
				updateContextCache({ fromFollowUp: true });
			}

			fireAnalyticsEvent(
				onFollowUpSuggestionClick({
					...commonAttributes,
					followUpString: followUp,
				}),
			);
			onClose?.();
			closeParentDialog?.();
			callbacks?.onFollowUpSuggestionClick?.();
		},
		[
			commonAttributes,
			fireAnalyticsEvent,
			setQuery,
			updateContextCache,
			answerString,
			searchSessionId,
			query,
			publish,
			onClose,
			closeParentDialog,
			brand,
			callbacks,
		],
	);

	return followUps.length > 0 ? (
		<AIAnswerFollowUpWrapper>
			<AIAnswerFollowupBody>
				<Stack space="space.075">
					{visibleFollowUps.map((followUp, index) => {
						if (brand === AiBrand.ROVO) {
							return (
								<Inline space="space.050" alignBlock="center">
									<FollowUpButton
										key={followUp}
										onClick={() => onPillClick(followUp)}
										followUpText={followUp}
										followUpIndex={index}
										followUpType="UNAVAILABLE"
										experienceId="sain"
									/>
									{index === 0 && canExpand && (
										<Box
											xcss={iconWrapperStyles}
											onClick={() => {
												showAllFollowUps ? setShowAllFollowups(false) : setShowAllFollowups(true);
											}}
										>
											{showAllFollowUps ? (
												<ChevronDownIcon
													color="currentColor"
													label=""
													testId="collapse-follow-ups-icon"
												/>
											) : (
												<ChevronRightIcon
													color="currentColor"
													label=""
													testId="expand-follow-ups-icon"
												/>
											)}
										</Box>
									)}
								</Inline>
							);
						}

						return (
							<Inline space="space.050" alignBlock="center">
								<AIAnswerFollowUpPill onClick={() => onPillClick(followUp)}>
									{followUp}
								</AIAnswerFollowUpPill>
								{index === 0 && canExpand && (
									<Box
										xcss={iconWrapperStyles}
										onClick={() => {
											showAllFollowUps ? setShowAllFollowups(false) : setShowAllFollowups(true);
										}}
									>
										{showAllFollowUps ? (
											<ChevronDownIcon
												color="currentColor"
												label=""
												testId="collapse-follow-ups-icon"
											/>
										) : (
											<ChevronRightIcon
												color="currentColor"
												label=""
												testId="expand-follow-ups-icon"
											/>
										)}
									</Box>
								)}
							</Inline>
						);
					})}
				</Stack>
			</AIAnswerFollowupBody>
		</AIAnswerFollowUpWrapper>
	) : null;
};
