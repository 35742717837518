import type { AnalyticsEventPayload } from '@atlaskit/analytics-next';

import { type commonAttributesType } from '../../ai-answer-dialog/types';

export const fetchCuratedDefinitionErrorPayload = (
	error: unknown,
	commonAttributes: commonAttributesType,
): AnalyticsEventPayload => {
	const { extraAttributes, source } = commonAttributes;

	return {
		action: 'failed',
		actionSubject: 'aiCuratedDefinitionFetch',
		source,
		eventType: 'track',
		attributes: {
			...extraAttributes,
			errorName: error instanceof Object && 'name' in error ? error.name : '',
			errorMessage: error instanceof Object && 'message' in error ? error.message : '',
			errorStack:
				error instanceof Object && 'stack' in error && typeof error.stack === 'string'
					? error.stack?.split('\n').at(1)
					: '',
		},
	};
};

export const updateDefinitionErrorPayload = (
	error: unknown,
	commonAttributes: commonAttributesType,
): AnalyticsEventPayload => {
	const { extraAttributes, source } = commonAttributes;

	return {
		action: 'failed',
		actionSubject: 'aiCuratedDefinitionUpdate',
		source,
		eventType: 'track',
		attributes: {
			...extraAttributes,
			errorName: error instanceof Object && 'name' in error ? error.name : '',
			errorMessage: error instanceof Object && 'message' in error ? error.message : '',
			errorStack:
				error instanceof Object && 'stack' in error && typeof error.stack === 'string'
					? error.stack?.split('\n').at(1)
					: '',
		},
	};
};
