import React, {
	Fragment,
	Suspense,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';

import { FormattedMessage, useIntl } from 'react-intl-next';

import { CardTemplate } from '../../../../constants';
import { messages } from '../../../../messages';
import { useAIAnswerContext } from '../../../ai-answer-context';
import { onAIAnswerReturnLinkClick } from '../../../analytics';
import { NLPSearchResultFormat } from '../../../types';
import {
	AIAnswerCloseLabelWrapper,
	AIAnswerResultBlurSection,
	AIAnswerResultBlurSectionText,
	AIAnswerResultSection,
} from '../styled';

const ReactRenderer = React.lazy(() =>
	import(/* webpackChunkName: "@atlaskit-internal_.renderer" */ '@atlaskit/renderer').then(
		({ ReactRenderer }) => {
			return {
				default: ReactRenderer,
			};
		},
	),
);

const MarkdownRenderer = React.lazy(() =>
	import(/* webpackChunkName: "react-markdown" */ 'react-markdown').then(
		({ default: ReactMarkdown }) => {
			return {
				default: ReactMarkdown,
			};
		},
	),
);

export const RenderedResultComponent = () => {
	const { formatMessage } = useIntl();
	const [isResultOverflow, setResultOverflow] = useState<boolean>(false);
	const [isResultExpanded, setResultExpanded] = useState<boolean>(false);
	const wrapperRef = useRef<HTMLDivElement | null>(null);
	const contentRef = useRef<HTMLDivElement | null>(null);
	const { commonAttributes, fireAnalyticsEvent, isReadingAids, cardTemplate, onClose } =
		useAIAnswerContext();
	const { answerString, answerFormat } = commonAttributes;
	const onCloseDialog = useCallback(() => {
		fireAnalyticsEvent(onAIAnswerReturnLinkClick(commonAttributes));
		onClose?.();
	}, [commonAttributes, fireAnalyticsEvent, onClose]);

	const enableShowMoreBlur = cardTemplate === CardTemplate.DEFAULT && isReadingAids;

	useEffect(() => {
		if (!wrapperRef.current || !contentRef.current) {
			return;
		}

		const resizeObserver = new ResizeObserver(() => {
			if ((wrapperRef.current?.offsetHeight ?? 0) < (contentRef.current?.offsetHeight ?? 0)) {
				setResultOverflow(true);
			}
		});

		resizeObserver.observe(contentRef.current);
		return () => resizeObserver.disconnect();
	}, []);

	const FormattedResult = useMemo(() => {
		let content;
		switch (answerFormat) {
			case NLPSearchResultFormat.ADF:
				content = <ReactRenderer document={JSON.parse(answerString)} />;
				break;
			case NLPSearchResultFormat.MARKDOWN:
				content = <MarkdownRenderer>{answerString}</MarkdownRenderer>;
				break;
			default:
				return <Fragment />;
		}
		return <div ref={contentRef}>{content}</div>;
	}, [answerFormat, answerString, contentRef]);

	return (
		<Fragment>
			<AIAnswerResultSection
				id="ai-answer-result-section"
				ref={wrapperRef}
				isReadingAids={isReadingAids && cardTemplate === CardTemplate.DEFAULT}
				isExpanded={isResultExpanded}
			>
				<Suspense fallback={<Fragment />}>{FormattedResult}</Suspense>
			</AIAnswerResultSection>
			{enableShowMoreBlur && isResultOverflow && !isResultExpanded && (
				<AIAnswerResultBlurSection onClick={() => setResultExpanded(true)}>
					<AIAnswerResultBlurSectionText>
						{formatMessage(messages.ai_answer_expand_button_label)}
					</AIAnswerResultBlurSectionText>
				</AIAnswerResultBlurSection>
			)}

			{!isReadingAids && onClose && (
				<AIAnswerCloseLabelWrapper>
					<FormattedMessage
						{...messages.ai_answer_dialog_cancel_label}
						values={{
							searchResultsLink: (
								<a onClick={onCloseDialog}>
									{formatMessage(messages.ai_answer_dialog_search_results_label)}
								</a>
							),
						}}
					/>
				</AIAnswerCloseLabelWrapper>
			)}
		</Fragment>
	);
};
