import React from 'react';

import { FormattedMessage } from 'react-intl-next';

import Avatar from '@atlaskit/avatar';

import { messages } from '../../../../../messages';
import { useAIAnswerContext } from '../../../../ai-answer-context';
import {
	onWhoAdditionalMatchProfileClick,
	onWhoAdditionalMatchProfileContextMenuClick,
} from '../../../../analytics';
import type { NLPSearchWhoResultType } from '../../../../types';
import {
	getTypedJSONResultData,
	getTypedJSONResultDataAssistanceService,
} from '../../../../utils/getTypedJSONResultData';

import {
	AIWhoAnswerFooter,
	AIWhoAnswerFooterHeader,
	AIWhoAnswerFooterResult,
	AIWhoAnswerFooterResultInfoHeader,
	AIWhoAnswerFooterResultInfoWrapper,
	AIWhoAnswerFooterResultMetadata,
	AIWhoAnswerFooterResultsWrapper,
} from './styled';

export const AIAnswerWhoFooter = () => {
	const { commonAttributes, fireAnalyticsEvent, onNavigate, assistanceServiceEnabled } =
		useAIAnswerContext();
	const { answerString } = commonAttributes;

	// answerString should have at least one result or else <AIAnswerError /> will be rendered
	const { parsedResult } = assistanceServiceEnabled
		? getTypedJSONResultDataAssistanceService(answerString)
		: getTypedJSONResultData(answerString);
	const additionalUserResults = (parsedResult as NLPSearchWhoResultType['user_metadata']).slice(1);
	const numAdditionalUserResults = additionalUserResults.length;

	return numAdditionalUserResults ? (
		<AIWhoAnswerFooter>
			<AIWhoAnswerFooterHeader>
				<FormattedMessage
					{...messages.ai_answer_who_questions_footer_header}
					values={{ numMatches: numAdditionalUserResults }}
				/>
			</AIWhoAnswerFooterHeader>
			<AIWhoAnswerFooterResultsWrapper>
				{additionalUserResults.map((userResult: any) => (
					<AIWhoAnswerFooterResult
						key={userResult.name}
						href={userResult.profile_url || undefined}
						onClick={(event) => {
							onNavigate(event);
							fireAnalyticsEvent(
								onWhoAdditionalMatchProfileClick({
									...commonAttributes,
									additionalMatchUserName: userResult.name,
									additionalMatchTitle: userResult.job_title || '',
								}),
							);
						}}
						onContextMenu={(event) => {
							onNavigate(event);
							fireAnalyticsEvent(
								onWhoAdditionalMatchProfileContextMenuClick({
									...commonAttributes,
									additionalMatchUserName: userResult.name,
									additionalMatchTitle: userResult.job_title || '',
								}),
							);
						}}
					>
						<Avatar
							src={userResult.avatar_url || undefined}
							size="medium"
							testId={userResult.name}
						/>
						<AIWhoAnswerFooterResultInfoWrapper>
							<AIWhoAnswerFooterResultInfoHeader>
								{userResult.name}
							</AIWhoAnswerFooterResultInfoHeader>
							<AIWhoAnswerFooterResultMetadata>
								{userResult.job_title}
							</AIWhoAnswerFooterResultMetadata>
						</AIWhoAnswerFooterResultInfoWrapper>
					</AIWhoAnswerFooterResult>
				))}
			</AIWhoAnswerFooterResultsWrapper>
		</AIWhoAnswerFooter>
	) : null;
};
