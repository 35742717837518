import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';

import { FormattedMessage, useIntl } from 'react-intl-next';

import { CardTemplate } from '../../../../constants';
import { messages } from '../../../../messages';
import { useAIAnswerContext } from '../../../ai-answer-context';
import { onAIAnswerReturnLinkClick } from '../../../analytics';
import {
	AIAnswerCloseLabelWrapper,
	AIAnswerResultBlurSection,
	AIAnswerResultBlurSectionText,
	AIAnswerResultSection,
} from '../styled';

export const PlaintextResultComponent = () => {
	const [isResultOverflow, setResultOverflow] = useState<boolean>(false);
	const [isResultExpanded, setResultExpanded] = useState<boolean>(false);
	const wrapperRef = useRef<HTMLDivElement | null>(null);
	const contentRef = useRef<HTMLDivElement | null>(null);
	const { formatMessage } = useIntl();
	const { commonAttributes, fireAnalyticsEvent, isReadingAids, cardTemplate, onClose } =
		useAIAnswerContext();
	const { answerString } = commonAttributes;
	const onCloseDialog = useCallback(() => {
		fireAnalyticsEvent(onAIAnswerReturnLinkClick(commonAttributes));
		onClose?.();
	}, [commonAttributes, fireAnalyticsEvent, onClose]);

	const enableShowMoreBlur = cardTemplate === CardTemplate.DEFAULT && isReadingAids;

	useEffect(() => {
		if (!wrapperRef.current || !contentRef.current) {
			return;
		}

		const resizeObserver = new ResizeObserver(() => {
			if ((wrapperRef.current?.offsetHeight ?? 0) < (contentRef.current?.offsetHeight ?? 0)) {
				setResultOverflow(true);
			}
		});

		resizeObserver.observe(contentRef.current);
		return () => resizeObserver.disconnect();
	}, []);

	return (
		<Fragment>
			<AIAnswerResultSection
				isReadingAids={isReadingAids && cardTemplate === CardTemplate.DEFAULT}
				ref={wrapperRef}
				isExpanded={isResultExpanded}
			>
				<div ref={contentRef}>{answerString}</div>
				{enableShowMoreBlur && isResultOverflow && !isResultExpanded && (
					<AIAnswerResultBlurSection onClick={() => setResultExpanded(true)}>
						<AIAnswerResultBlurSectionText>
							{formatMessage(messages.ai_answer_expand_button_label)}
						</AIAnswerResultBlurSectionText>
					</AIAnswerResultBlurSection>
				)}
			</AIAnswerResultSection>

			{!isReadingAids && onClose && (
				<AIAnswerCloseLabelWrapper>
					<FormattedMessage
						{...messages.ai_answer_dialog_cancel_label}
						values={{
							searchResultsLink: (
								<a onClick={onCloseDialog}>
									{formatMessage(messages.ai_answer_dialog_search_results_label)}
								</a>
							),
						}}
					/>
				</AIAnswerCloseLabelWrapper>
			)}
		</Fragment>
	);
};
