import React, { Fragment } from 'react';

import { useAIAnswerContext } from '../../../../ai-answer-context';
import { type NLPSearchWorkResultType } from '../../../../types';
import { getTypedJSONResultDataAssistanceService } from '../../../../utils/getTypedJSONResultData';
import { RecentWorkList } from '../recent-work-list';
import { ProfileSection } from '../user-profile-section';

export const AIAnswerWorkResult = () => {
	const { commonAttributes, assistanceServiceEnabled } = useAIAnswerContext();
	const { answerString } = commonAttributes;

	const workResult: NLPSearchWorkResultType = assistanceServiceEnabled
		? ({
				user_metadata_with_activity:
					getTypedJSONResultDataAssistanceService(answerString).parsedResult,
			} as NLPSearchWorkResultType)
		: JSON.parse(answerString);
	const userWorkResult = workResult.user_metadata_with_activity[0];
	const activities = userWorkResult.recentActivity;

	return (
		<Fragment>
			<ProfileSection
				name={userWorkResult.name}
				email={userWorkResult.email}
				avatarUrl={userWorkResult.avatar_url}
				profileUrl={userWorkResult.profile_url}
				jobTitle={userWorkResult.job_title}
				department={userWorkResult.department}
				location={userWorkResult.location}
				time={userWorkResult.time}
			/>
			<RecentWorkList
				name={userWorkResult.name}
				activities={activities}
				viewAllUrl={userWorkResult.viewAllLink}
			/>
		</Fragment>
	);
};
