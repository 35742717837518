import { type userType } from '../common-types';

export interface recommendedUserType {
	id: string;
	user: userType;
}

export interface KnowledgeCardPrimaryUserDataQueryType {
	user: {
		id: string;
		picture: string;
		name: string;
		accountStatus: string;
		email?: string | null;
		zoneinfo?: string | null;
		extendedProfile?: {
			jobTitle?: string | null;
			department?: string | null;
			location?: string | null;
			organization?: string | null;
		};
	};
	smarts: {
		recommendedUser: recommendedUserType[];
	};
}

export interface KnowledgeCardPrimaryUserDataVariables {
	accountId: string;
	accountIdARI: string;
	tenantId: string;
	requestingUserId: string;
}

// we construct accountIdARI by appending "ari:cloud:identity::user/" + accountId
export const KnowledgeCardPrimaryUserDataQuery = `
    query KnowledgeCardPrimaryUserDataQuery(
        $accountId: String!
        $accountIdARI: ID!
        $tenantId: String!
        $requestingUserId: String!
        ) {
        user(accountId: $accountIdARI) {
            id
            picture
            name
            accountStatus
            ... on AtlassianAccountUser {
                email
                zoneinfo
                extendedProfile {
                    jobTitle
                    department
                    organization
                    location
                }
            }
        }
        smarts {
            recommendedUser(recommendationsQuery: {
            context: {
                userId: $accountId, tenantId: $tenantId
            }, 
            modelRequestParams: {
                caller: "search-ai", 
                experience: "CgUserNearbyUser"
            }, 
            requestingUserId: $requestingUserId, 
            maxNumberOfResults: 8
            }
            ) {
            id
            user {
                id
                picture
                name
                accountStatus
                ... on AtlassianAccountUser {
                email
                extendedProfile {
                    jobTitle
                    department
                }
                }
            }
            }
        }
    }
`;
