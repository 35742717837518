import React from 'react';

import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import { AITopicsByPerson } from '../ai-topics';
import { type AITopicsByPersonProps } from '../ai-topics/ai-topics-by-person';

const topicsContainer = xcss({
	background: token('elevation.surface.sunken'),
	borderRadius: '4px',
});

export const KnowledgeCardsTopics = (props: AITopicsByPersonProps) => {
	return (
		<Box xcss={topicsContainer}>
			<AITopicsByPerson {...props} />
		</Box>
	);
};
